import { AnimationOnScroll } from 'react-animation-on-scroll';
import './styles.scss';
import {
    facebook,
    instagram,
    ajo_desktop,
    perejil_desktop,
} from '../../assets'

const RRSS = () => {
    return (
        <div className="rrss-cont">

            <AnimationOnScroll animateOnce animateIn="animate__backInUp">
                <img src={ajo_desktop} alt="Cabeza de ajo" id="ajo_desktop-desktop" className='desktop-d-none' />
            </AnimationOnScroll>

            <div className='ml-5 mt-2'>
                <p className="helvetica-bold uppercase">follow us:</p>
                <p className="helvetica-bold social-media">
                    <a href='https://www.instagram.com/helenashummus/' 
                        target="_blank" rel="noreferrer">
                            <img src={instagram} alt="" />
                    </a> 
                    <a href='https://www.facebook.com/Helenashummus' 
                        target="_blank" rel="noreferrer">
                            <img src={facebook} alt="" />
                    </a>
                    <span> /Helenashummus</span>
                </p>
            </div>

            <div id='center-div'>
            </div>

            <img src={perejil_desktop} alt="perejil" className='desktop-d-none' id='perejil-bottom-img' />
        </div>
    )
}

export default RRSS;