import { AnimationOnScroll } from 'react-animation-on-scroll';
import { HHLogo } from '../assets';
import { Grabygo } from '../assets';
import { Product } from '../assets';

const Header = () => {
    return (
        <header className="header">
            <div className="logo">
                <img src={HHLogo} alt="HELENA`S HUMMUS LOGO" className="img-logo animate__animated"></img>
            </div>
            <div className="headerText">
                <AnimationOnScroll animateOnce animateIn="animate__backInUp">
                    <h2 className='rollerCoresReg m-0 font-2rem white'>Delicious Flavor</h2>
                    <p className="text mt-0">That doesn´t require<br></br><span className="text2">refrigeration</span></p>
                </AnimationOnScroll>
            </div>
            <div className="products">
                <AnimationOnScroll animateOnce animateIn="animate__fadeIn" initiallyVisible>
                    <img src={Grabygo} alt="GRAB&GO" className="grabygo animate__animated"></img>
                    <img src={Product} alt="HUMMUS PRODUCTS" className="product animate__animated"></img>
                </AnimationOnScroll>
            </div>
        </header>
    );
}


export default Header;