import { Helmet } from "react-helmet";

const Head = () => {

    return(
        <Helmet>
            <title>Helena's Hummus | Delicious flavor that doesn't require refrigeration</title>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content="Helena's Hummus | Delicious flavor that doesn't require refrigeration" />
        </Helmet>
    )
}

export default Head;