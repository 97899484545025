import { AnimationOnScroll } from 'react-animation-on-scroll';
import { 
    pan_pita,
    pan_pita2,
    perejil,
    ajo_diente,
    garbanzo_bg,
    hummus3_desktop,
} from '../../assets';
import './styles.scss';

const Description = () => {
    return (
        <div className="description">

            {/* Imagenes para desktop */}
            <img src={hummus3_desktop} alt="Plato de hummus" id="hummus-desktop" className='dsktp-d-none' />
            <img src={pan_pita} alt="Pan de pita" id="pan-pita-desktop-1" className='dsktp-d-none' />

            <div id='images-bg-food'>
                {/* Imagenes para mobile */}
                <img src={pan_pita} alt="Pan de pita" id="pan-pita-1" />
                <AnimationOnScroll animateOnce animateIn="animate__fadeInDown">
                    <img src={perejil} alt="Perejil" id="perejil-img" />
                </AnimationOnScroll>
                <img src={ajo_diente} alt="Diente de Ajo" id="ajo-img" />
                <AnimationOnScroll animateOnce animateIn="animate__fadeInDown">
                    <img src={garbanzo_bg} alt="Garbanzo" id="garbanzo_bg" />
                </AnimationOnScroll>
                <img src={pan_pita2} alt="Pan de pita" id="pan-pita-2" />
            </div>
            <AnimationOnScroll animateOnce animateIn="animate__backInUp">
                <p className="helvetica-thin text-center">
                    <span className="rollerCoresReg">HELENA´S HUMMUS IN A POUCH</span> for <br />
                    on-the-go cravings <span className="helvetica-bold uppercase">OUR HUMMUS</span> is made with <br />
                    selected chickpeas, olive oil and natural ingredients.
                </p>
            </AnimationOnScroll>
            <br />
            <AnimationOnScroll animateOnce animateIn="animate__backInUp">
                <p className="rollerCoresReg my-0">HELENA'S HUMMUS</p>
                <p className="helvetica-bold my-0">is gluten free and has no preservatives.</p>
            </AnimationOnScroll>
        </div>
    )
}

export default Description;