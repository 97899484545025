import Description from '../components/Description';
import FoodsPictures from '../components/FoodsPictures';
import Head from '../components/Head';
import Header from '../components/Header';
import Properties from '../components/Properties/Properties';
import PreFooter from '../components/PreFooter';
import RRSS from '../components/RRSS/RRSS';

import { useState, useEffect } from 'react';

function Home() {

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
          };
          window.addEventListener('resize', handleWindowResize);
          return () => {
            window.removeEventListener('resize', handleWindowResize);
          };
    }, []);

    return (
        <>
            <Head />
            <Header/>
            <Properties />
            <Description />
            <FoodsPictures />
            {
                (windowSize[0] < 768)
                    && <PreFooter />
            }
            <RRSS />
        </>
    );
}


export default Home;