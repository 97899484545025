import {
    Icon_props1,
    Icon_props2,
    Icon_props3,
    Icon_props4,
    Icon_props5,
    Icon_props6,
} from '../../assets';
import './style.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useState, useEffect } from 'react';

const images_props = [
    {icon_src: Icon_props1, alt: "Made with olive oil icon"},
    {icon_src: Icon_props2, alt: "Non GMO Project"},
    {icon_src: Icon_props3, alt: "No Preservatives"},
    {icon_src: Icon_props4, alt: "Plant Based Protein"},
    {icon_src: Icon_props5, alt: "Natural Ingredients"},
    {icon_src: Icon_props6, alt: "Self-Stable"},
];

const Properties = () => {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
          };
          window.addEventListener('resize', handleWindowResize);
          return () => {
            window.removeEventListener('resize', handleWindowResize);
          };
    }, []);

    return (
        <>
            <div className='flex justify-center props-container'>
                <OwlCarousel 
                    className='owl-theme' 
                    loop={windowSize[0] >= 768 ? false : true}
                    dots={false}
                    items={windowSize[0] >= 768 ? 6 : 4}>
                    {
                        images_props.map((icon, i) => {
                            return <img 
                                key={i}
                                src={icon.icon_src} 
                                alt={icon.alt} 
                                title={icon.alt}
                                className="item props-icon-img" />
                        })
                    }
                </OwlCarousel>
            </div>
        </>
    )
}

export default Properties;