import { AnimationOnScroll } from 'react-animation-on-scroll';
import {
    hummus3_desktop,
    perejil2,
    garbanzo_picture,
    morron,
    elipse_data,
    pan_pita2,
    morron2_desktop,
    garbanzo_bg,
    hummus_blanco_desktop,
} from '../../assets';
import './styles.scss';

const FoodsPictures = () => {
    return (
        <>
            <div id='food-images-container'>
                <img src={morron} alt="Imagen morron" id='morron-mob'/>
                <img src={perejil2} alt="Imagen perejil" id='perejil-mob' />
                <img src={garbanzo_picture} alt="Imagen garbanzo" id='garbanzo-mob' />
                <img src={hummus3_desktop} alt="Plato de hummus" id='plato-hummus-mob' />
            </div>

            <div className='flex' id='elipse-data-img'>
                <img src={hummus_blanco_desktop} alt="Plato de hummus" id="hummus_blanco_desktop" className='desktop-d-none' />

                <img src={elipse_data} alt='Información sobre Helena Hummus' id='helena-hummus-elipse' />
                <AnimationOnScroll duration={3} animateOnce animateIn="animate__backInUp">
                    <img src={garbanzo_bg} alt="Garbanzo" id="garbanzo-desktop" className='desktop-d-none' />
                </AnimationOnScroll>
                <AnimationOnScroll duration={3} animateOnce animateIn="animate__fadeIn">
                    <img src={pan_pita2} alt="Pan de pita" id="pan-pita-2-desktop" className='desktop-d-none' />
                </AnimationOnScroll>
                <AnimationOnScroll duration={3} animateOnce animateIn="animate__fadeIn">
                    <img src={morron2_desktop} alt="Morron" id="morron-2-desktop" className='desktop-d-none' />
                </AnimationOnScroll>
            </div>
        </>
    )
}

export default FoodsPictures;