import './styles.scss';
import {
    hummus_blanco,
    ingredients_mob,
} from '../../assets';

const PreFooter = () => {
    return(
        <div id='mob-prefooter'>
            <img src={hummus_blanco} alt="Plato de hummus" id='plato-hummus-mob' />
            <img src={ingredients_mob} alt="Ingredientes, garbanzo, perejil y ajo" id='ingredients-hummus-mob' />
        </div>
    )
}

export default PreFooter;