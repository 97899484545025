import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "animate.css/animate.min.css";
import "./css/main.scss";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
